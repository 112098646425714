#ecetoc {
  .bg-page-container {
    background-image: url('../../assets/images/header-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 48px;
    position: relative;
    height: 100%;

    .header-logo {
      text-align: center;
      svg {
        width: 201px;
        height: 40px;
        margin-bottom: 211px;
      }
    }
    .card {
      width: 640px;
      margin: 0 auto;
      padding: 0 110px;

      p {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 48px;
      }
      .card-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;
        margin-top: 48px;
        text-align: center;
        letter-spacing: 0.15px;
      }
      .action-buttons {
        margin-bottom: 32px;
        text-align: right;
        .half-w {
          width: calc(50% - 10px);
          margin-right: 0;
          &:first-child {
            margin-right: 20px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .action-links {
        text-align: center;
        margin-bottom: 24px;
        a {
          text-decoration: none;
          margin-right: 24px;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #01c4a5;
          &:hover {
            text-decoration: underline;
          }
          &.underlined {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      .MuiFormHelperText-root {
        text-align: left;
        margin-bottom: 0;
      }
      .v-hidden {
        visibility: hidden;
        width: 0;
        height: 0;
      }
      .MuiFormHelperText-root {
        margin-bottom: -14px;
        font-size: 12px;
        line-height: 12px;
      }
      .register-success {
        margin: 100px 0;
      }
    }
    @media all and (max-width: 940px) {
      .header-logo svg {
        width: 228px;
        height: 45px;
      }
      .card {
        width: 600px;
        padding: 0 62px;
      }
    }
    @media all and (max-width: 680px) {
      .header-logo svg {
        margin-bottom: 91px;
      }
      .card {
        width: calc(100% - 80px);
        padding: 0;
      }
    }
    @media all and (max-width: 380px) {
      .header-logo svg {
        margin-bottom: 12vh;
      }
      .card {
        width: calc(100% - 40px);
      }
    }
  }
}
