#ecetoc #landing {
  header {
    background-image: url('../../assets/images/header-bg.svg');
    background-repeat: no-repeat;
    background-size: auto 882px;
    background-position: center top;
    background-color: #f0f2f0;
    border-bottom: 30px solid #01c4a5;
    height: 840px;
    .width-wrapper-header {
      width: 860px;
      margin: 0 auto;
    }
    .header-logo {
      text-align: center;
      svg {
        width: 201px;
        height: 40px;
        margin-top: 48px;
      }
    }
    h1 {
      text-align: center;
      margin-top: 176px;
      margin-bottom: 24px;
      font-weight: bold;
      font-size: 54px;
      line-height: 72px;
      letter-spacing: 0.25px;
      color: #191b1e;
    }
    p {
      margin: 0 auto;
      text-align: center;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 56px;
    }
    .header-buttons {
      text-align: center;
    }
  }
  .section1 {
    background-color: #bef0e6;
    padding-top: 97px;
    padding-bottom: 72px;
  }
  .section2 {
    background-color: #01c4a5;
    padding: 90px 0;

    .width-wrapper {
      display: flex;
      div {
        width: 50%;
        text-align: center;
        height: 188px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .icon svg {
          min-height: 44px;
        }
      }
    }
    .value {
      display: block;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.15px;
      color: #fff;
    }
    p {
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0.15px;
      color: #fff;
      line-height: 0.5;
    }
  }
  .section3 {
    padding: 96px 0;
    .required-input {
      color: #01c4a5;
      margin-top: 72px;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
    }
  }
  footer {
    background-color: #01c4a5;
    padding: 96px 0;
    h2 {
      text-align: center;
      color: #fff;
      margin-bottom: 48px;
    }
    .bold-p {
      text-align: center;
      color: #fff;

      span {
        display: block;
      }
    }
    .footer-link {
      text-align: center;
      a {
        color: #fff;
        text-decoration: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media all and (max-width: 940px) {
    header .width-wrapper-header {
      width: 600px;
      svg {
        width: 220px;
        height: 44px;
      }
      h1 {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.15px;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .width-wrapper {
      width: 600px;
      .bold-p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  @media all and (max-width: 680px) {
    header .width-wrapper-header {
      width: 100%;
      padding: 0 40px;
      svg {
        width: 262px;
        height: 52px;
      }
      .MuiButton-root {
        margin-right: 10px;
        width: calc(50% - 5px);
        max-width: 200px;
        &:last-child {
          margin-right: 0;
        }
      }
      h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .width-wrapper {
      width: 100%;
      padding: 0 40px;
      h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
      .bold-p {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .section2 .width-wrapper {
      display: block;
      div {
        width: 100%;
        margin-bottom: 74px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media all and (max-width: 380px) {
    header .width-wrapper-header {
      padding: 0 30px;
    }
    .width-wrapper {
      padding: 0 30px;
    }
  }
}
