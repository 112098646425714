#ecetoc #dashboard {
  .filters {
    margin-bottom: 24px;
    button {
      border-radius: 0 2px 2px 0;
      width: 200px;
      position: relative;
    }
    .clickable {
      cursor: pointer;
    }
    .search {
      margin-bottom: 24px;
      .MuiTextField-root {
        width: calc(100% - 220px);
        margin-right: 20px;
      }
      .MuiInputBase-root {
        height: 48px;
        border-radius: 2px 0 0 2px;
      }
      svg {
        fill: #01c4a5;
      }
    }
    .selects {
      display: flex;
      margin-bottom: 24px;
      .filter-1 {
        width: calc(50% - 120px);
        margin-right: 20px;
      }
      .filter-2 {
        width: calc(50% - 120px);
        margin-right: 20px;
        &.disabled {
          .MuiFormControl-root {
            label {
              color: #a7aaaf;
            }
            .MuiInputBase-root {
              background-color: #e8eaed;
            }
          }
        }
      }
    }
    .type .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
      .MuiFormControlLabel-root {
        width: auto;
        margin-right: 50px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .MuiFormControl-root {
      width: 100%;
      label {
        top: -3px;
      }
      .MuiInputBase-root {
        height: 48px;
      }
      .MuiSelect-select:focus {
        background-color: unset;
      }
      .MuiSelect-root .MuiSvgIcon-root {
        display: none;
      }
    }
    @media only screen and (max-width: 768px) {
      .search {
        .MuiTextField-root {
          width: 100%;
          margin-right: 0;
        }
        button {
          display: none;
        }
      }
      .selects {
        display: flex;
        flex-wrap: wrap;
        .filter-1,
        .filter-2,
        button {
          margin-right: 0;
          margin-bottom: 24px;
          width: 100%;
        }
      }

      .section-width-wrapper .small-p.highlighted {
        margin-bottom: 56px;
      }
    }
    @media only screen and (max-width: 520px) {
      .type .MuiFormGroup-root {
        .MuiFormControlLabel-root {
          margin-right: 15px;
          span {
            font-size: 14px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.MuiList-root.MuiMenu-list {
  min-width: 280px;
}
.MuiPaper-root .MuiListItem-root.MuiMenuItem-root {
  min-height: 48px;
  position: relative;
  .MuiSvgIcon-root {
    position: absolute;
    right: 16px;
    width: 16px;
  }
  .MuiCheckbox-root {
    position: absolute;
    right: 4px;
    .MuiSvgIcon-root {
      width: 24px;
    }
  }
}
