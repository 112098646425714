#ecetoc .section-width-wrapper {
  .data-list {
    .kind {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e8eaed;
      width: 70px;
      border-radius: 174px;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 17px;

      svg {
        fill: #01c4a5;
        margin-right: 6px;
        width: 15px;
      }
    }

    h4 {
      font-weight: bold;
      margin-bottom: 17px;
      margin-top: 16px;
    }

    p {
      margin-bottom: 0;
      width: 90%;
      word-wrap: break-word;

      a {
        color: #01c4a5;
        text-decoration: underline;
        margin-bottom: 16px;
      }
    }

    .MuiAccordionSummary-expandIcon {
      position: absolute;
      top: 20px;
      right: 30px;
      padding: 0;
    }

    .expand-indicator {
      position: absolute;
      top: 56px;
      right: -10px;
      background: #e8eaed;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.26), 0px 2px 10px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      padding: 4px 24px;
    }
    h4,
    .expand-indicator {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    svg {
      fill: #191b1e;
    }

    div,
    h4 {
      font-family: Roboto, sans-serif;
    }
  }
}

@media only screen and (max-width: 480px) {
  #ecetoc .section-width-wrapper .data-list .expand-indicator {
    display: none;
  }
}
