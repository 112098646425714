#ecetoc #dashboard {
  .section-width-wrapper {
    .loading {
      margin: 50px 0;
      text-align: center;
    }
    .data-list {
      .MuiAccordion-root {
        border: 1px solid #a7aaaf;
        border-bottom: none;
        .summary {
          max-width: 100%;
          .hasIcon {
            svg {
              position: relative;
              top: 3px;
              font-size: 16px;
              left: 10px;
            }
          }
        }
        &:hover {
          background-color: #f7f8f9;
        }
        &:first-child {
          border-radius: 2px 2px 0 0;
        }
        &:last-child {
          border-radius: 0 0 2px 2px;
        }
      }
      .MuiAccordionSummary-root {
        padding: 0 24px;
      }
      .MuiAccordionSummary-content {
        margin: 24px 0;
      }
    }
    span.match {
      background-color: #01c4a5;
    }
    .small-p.highlighted {
      color: #01c4a5;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 48px;
      margin-top: -40px;
    }
    width: 1160px;
    margin: 0 auto;
    padding-bottom: 142px;
    h2 {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
    }
    .small-p {
      width: 750px;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.15px;
      margin-bottom: 72px;
    }
    .question h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.15px;
      margin-bottom: 24px;
      p {
        line-height: 24px;
        letter-spacing: 0.25px;
      }
    }
    .MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
    @media only screen and (max-width: 1260px) {
      width: 100%;
      padding-left: 60px;
      padding-right: 60px;
      .small-p {
        width: 100%;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 146px;
      .small-p {
        margin-bottom: 48px;
      }
    }
    @media only screen and (max-width: 480px) {
      padding-bottom: 110px;
      h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px;
      }
      .question h3 {
        font-size: 18px;
        line-height: 24px;
      }
      .question p {
        font-size: 14px;
        line-height: 16px;
      }
      .small-p {
        margin-bottom: 48px;
      }
    }
    @media only screen and (max-width: 375px) {
      padding: 0 24px 110px;
    }
    @media only screen and (max-width: 320px) {
      padding: 0 16px 110px;
    }
  }
  .footer {
    padding: 76px 40px;
    background-color: #bef0e6;
    text-align: center;
    h3 {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.15px;
      text-align: center;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      text-align: center;
      margin-bottom: 0;
      a {
        color: #01c4a5;
      }
    }
    @media only screen and (max-width: 480px) {
      h3 {
        font-size: 24px;
        line-height: 32px;
      }
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
