#ecetoc .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 16px;

    .views {
        display: flex;
        align-items: center;
    }

    .rpp {
        margin-right: 26px;
    }

    .MuiOutlinedInput-root {
        margin-right: 20px;

        .MuiOutlinedInput-input {
            padding: 4px 16px;
            width: 58px;
            font-size: 12px;
        }
    }

    .current {
        margin-right: 58px;
    }

    .controls {
        svg {
            margin-right: 80px;

            &:last-of-type {
                margin-right: 0;
            }

            &.disabled {
                fill: #a7aaaf;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #ecetoc .pagination {
        flex-direction: column;
    }

    #ecetoc .pagination .views,
    #ecetoc .pagination .controls {
        width: 90%;
        justify-content: space-between;
    }

    #ecetoc .pagination .controls {
        display: flex;
        align-items: center;
        margin-top: 30px;
    }

    #ecetoc .pagination .controls svg,
    #ecetoc .pagination .MuiOutlinedInput-root,
    #ecetoc .pagination .rpp,
    #ecetoc .pagination .current {
        margin-right: 0;
    }
}

@media only screen and (max-width: 375px) {
    #ecetoc .pagination .views,
    #ecetoc .pagination .controls {
        width: 100%;
    }
}
