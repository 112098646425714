html,
body,
#root {
  height: 100%;
}
* {
  box-sizing: border-box;
}
#ecetoc {
  height: 100%;
  h1,
  h2,
  h3,
  h4,
  p,
  a {
    font-family: Roboto, sans-serif;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #191b1e;
    margin-bottom: 24px;
    &.bold-p {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
    &.last {
      margin-bottom: 0;
    }
  }
  .width-wrapper {
    width: 640px;
    margin: 0 auto;
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
    color: #191b1e;
  }
  .form {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 24px;
    }
    .MuiButtonBase-root {
      width: 100%;
      margin-top: 24px;
    }
  }
  .MuiButton-root {
    height: 48px;
    margin-right: 20px;
    width: 200px;
    border-radius: 2px;
    .MuiButton-label {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.75px;
    }

    &:last-child {
      margin-right: 0;
    }
    &.full-w {
      margin-right: 0;
      width: 100%;
      margin-top: 24px;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 2px;
  }
  .MuiFormHelperText-root {
    text-align: left;
    margin-top: 2px;
    margin-bottom: -14px;
    font-size: 12px;
    line-height: 12px;
    &.Mui-error {
      color: #f44336;
    }
  }
  .register-success {
    margin: 100px 0;
    color: #01c4a5;
  }
}
