#ecetoc #dashboard header {
  height: 80px;
  background-color: #01c4a5;
  margin-bottom: 72px;

  .header-width-wrapper {
    width: 1380px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    .header-logo {
      margin: 20px 0;
      a {
        margin: 0;
        padding: 0;
      }
      svg {
        width: 200px;
        height: 40px;
        display: block;
      }
    }
    a {
      display: inline-block;
      color: #fff;
      text-align: center;
      margin: 16px 0;
      padding: 16px 0;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .faq {
      width: 90px;
      height: 48px;
      margin-right: 20px;
    }
    .sign-out {
      width: 200px;
      height: 48px;
    }

    .faq-links-mobile {
      display: none;
    }
    @media only screen and (max-width: 1330px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      .faq-links {
        display: none;
      }
      padding: 0 40px;
      .header-logo svg {
        width: 150px;
      }
      a {
        color: #fff;
        width: 100%;
        font-size: 30px;
        margin: 24px 0;
      }
      .faq-links-mobile {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        width: 100%;

        svg:hover {
          cursor: pointer;
        }

        .links-mobile {
          background-color: #01c4a5;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: fixed;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 999;

          svg {
            color: #fff;
            position: absolute;
            top: 30px;
            right: 15%;
          }
        }
      }
    }
    @media only screen and (max-width: 480px) {
      padding: 0 40px;
      .header-logo svg {
        width: 126px;
      }
      .faq-links-mobile .links-mobile svg {
        right: 40px;
      }
    }
    @media only screen and (max-width: 375px) {
      padding: 0 24px;
    }
    @media only screen and (max-width: 320px) {
      padding: 0 16px;
    }
  }
}
